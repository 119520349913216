<template>
  <a-card>
    <div class="management-div">
      <div class="auto-box">
        <div v-for="(item,index) in nodeList" :key="index">
          <div class="arrow-icon-box" v-if="index > 0">
            <div class="arrow-icon">
            </div>
            <!--<a-icon-->
            <!--  type="arrow-down"-->
            <!--  style="font-size: 30px; position: relative;left: -13px"-->
            <!--/>-->
          </div>
          <div class="next-box">
            <div class="one-box">
              {{ item.nodeName }}
            </div>
            <div class="click-item-box" v-if="index > 0" @click="changeNodeItem(item)">
              <span v-if="item.nodeCode == 'comprehensive_evaluation'">默认为工单接收人</span>
              <span v-else-if="item.dealerManScope == 1">指定单个成员{{ item.dealerManName }}</span>
              <span v-else-if="item.dealerManScope == 2">指定{{ item.dealerManName }}等{{
                  (item.dealerManIdArray || []).length
                }}个成员，由上一个节点指定</span>
              <span v-else> --</span>
              <a-icon
                  type="right"
                  style="font-size: 30px; color: #595959;padding-left: 10px"
                  v-if="item.nodeCode != 'comprehensive_evaluation'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--节点配置-->
    <a-drawer
        :visible="visible"
        title="节点配置"
        placement="right"
        :width="800"
        :mask-closable="false"
        @close="visible = false"
    >
      <a-row>
        <a-col span="24">
          <span class="item-title">流程节点：</span>
          <span>{{ NodeItem.nodeName }}</span>
        </a-col>
      </a-row>
      <a-row style="padding-top: 30px">
        <a-col span="24">
          <span class="item-title">受理人员范围：</span>
        </a-col>
      </a-row>
      <a-row style="padding-top: 10px">
        <a-col span="24">
          <a-radio-group name="radioGroup" v-model="NodeItem.dealerManScope">
            <a-radio :value="1">
              指定单个成员
            </a-radio>
            <a-radio :value="2">
              指定多个成员
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row style="padding-top: 30px">
        <a-col span="24">
          <span class="item-title">添加人员：</span>
        </a-col>
      </a-row>
      <a-row style="padding-top: 30px">
        <a-col span="24">
          <OrgPick ref="ORG" :multiple="NodeItem.dealerManScope != 1" @change="getCheckedUser"
                   :value="CheckedUserItem"/>
        </a-col>
      </a-row>
      <a-row style="padding-top: 30px">
        <a-col span="16"></a-col>
        <a-col span="6">
          <div style="display:flex;justify-content: space-between">
            <a-button @click="visible = false">取 消</a-button>
            <a-button type="primary" style="margin-left: 20px" @click="handleSubmit">确 定</a-button>
          </div>
        </a-col>
        <a-col span="1"></a-col>
      </a-row>
    </a-drawer>

  </a-card>
</template>

<script>

import _https from "@/api/workOrder";
import OrgPick from "@/components/OrgPick";
import { isEmpty, isEqual, remove } from "lodash-es";

export default {
  data() {
    return {
      nodeList: [],
      visible: false,
      tree: [],
      CheckedUserItem: [],
      NodeItem: {},
    };
  },
  created() {
    this.getWordDetail();
  },
  components: {OrgPick},
  methods: {
    getWordDetail() {
      _https.getWordDetail()
          .then((res) => {
            if(res.code == 200) {
              this.nodeList = res.data[0].nodeInfo || [];
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {
            this.saveLoading = false;
          });
    },
    changeNodeItem(item) {
      this.CheckedUserItem = [];
      if(item.nodeCode == "comprehensive_evaluation") {
        return;
      }
      this.NodeItem = item || {};
      if(item.dealerManScope == 1) {
        item.oauthUserId && this.CheckedUserItem.push(item.oauthUserId);
      } else if(item.dealerManScope == 2) {
        this.CheckedUserItem = item?.oauthUserId?.split(",");
      }
      this.visible = true;
      this.$refs.ORG?.handleResetTree();
    },
    getCheckedUser({checkedUser}) {
      console.log("checkedUser", checkedUser);
      this.checkedUser = checkedUser || [];
    },
    // CAS根据企微id查询是否绑定百夫长账号
    checkUserOauthBatch(ids) {
      return new Promise((resolve, reject) => {
        _https.checkUserOauthBatch(ids)
            .then((res) => {
              if(res.code == 200) {
                const keys = remove(res.data?.unboundBfzAccountWxIds?.split(",") || [], (n) => !isEmpty(n));
                if(isEmpty(keys)) {
                  resolve(res.data, true);
                  return;
                }
                this.$refs.ORG.handleBatchRemove(keys);
                const user = this.$refs.ORG.handlePersonById(keys);
                this.$message.error(`${ user.map(v => v.title).join(",") } 还未绑定百夫长账号，请联系工作人员！`);
                resolve(res.data, false);
              } else {
                this.$message.error(res.message || "操作失败");
                reject(res.message || "操作失败");
              }
            }).catch(reject);
      });
    },
    handleSubmit() {
      if(!this.checkedUser?.length) {
        this.$message.error("受理人不能为空！");
        return;
      }
      if(this.NodeItem.dealerManScope == 1) {
        if(this.checkedUser?.length > 1) {
          this.$message.error("指定单个成员时，只能选择一位成员！");
          return;
        }
      }
      let ids = [];
      let names = [];
      this.checkedUser?.forEach(item => {
        ids.push(item.key);
        names.push(item.title);
      });
      this.checkUserOauthBatch(ids)
          .then(res => {
            const bfzList = res.boundBfzAccountList;
            const checked = this.checkedUser;
            const receiverList = [];
            const notList = [];
            checked?.forEach(item => {
              const col = bfzList?.find(v => isEqual(v.oauth_user_id, item.key));
              if(isEmpty(col)) {
                notList.push(item.title);
              } else {
                receiverList.push({
                  dealerManId: col.user_id,
                  dealerManName: item.title,
                  oauthUserId: item.key
                });
              }
              if(notList.length) {
                const names = notList.join(",");
                this.$message.error(`${ names }不可以配置受理人`);
              }
            });
            // let dealerManIds = res.boundBfzAccountList.map(v => v.user_id);
            if(res) {
              let params = {
                moduleId: this.NodeItem.moduleId, // 对应的工单流程模板id
                nodeCode: this.NodeItem.nodeCode, // 对应的工单节点id
                orderType: 1, // 取数工单 1
                dealerManScope: this.NodeItem.dealerManScope,//受理人范围类型：-1、没指定范围，1、指定单个成员，2、指定多个成员
                receiverList,
                // dealerManIds: dealerManIds.join(","), // 工单节点受理人ID：多个id以逗号隔开
                // dealerManNames: names.join(","), // 工单节点受理人ID：多个id以逗号隔开
                // oauthUserIds: ids.join(","), // 工单节点受理人企微id，多个企微id以逗号隔开
              };
              _https.postOrderNodeReveicerEntity(params)
                  .then((res) => {
                    if(res.code == 200) {
                      this.getWordDetail();
                      this.$message.success("节点受理人配置成功");
                    } else if(res.code == 400) {
                      console.log(res.message, "不需要提示");
                    } else {
                      this.$message.error(res.message || "操作失败");
                    }
                    this.visible = false;
                  });
            }
          });
    },
  },
};
</script>

<style lang="less" scoped>
.management-div {
  display: flex;
  justify-content: center;
  align-items: center;

  .auto-box {

    .one-box {
      width: 420px;
      height: 60px;
      border: 3px solid #595959;
      line-height: 60px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      position: relative;
    }

    .arrow-icon-box {
      position: relative;
      left: 50%;
    }

    .arrow-icon {
      height: 140px;
      width: 3px;
      background: #595959;
      z-index: 999999;
    }

    .next-box {
      .click-item-box {
        width: 420px;
        height: 100%;
        border: 3px solid #595959;
        //line-height: 80px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        border-top: 0px;
        cursor: pointer;
      }
    }
  }
}

.item-title {
  font-size: 14px;
  font-weight: bold;
}

</style>
